import { Link } from 'gatsby'
import React from 'react'
import Disclaimer from '../../Disclaimer'

interface PostsRelatedProps {
    slug: string,
    image: any,
    title: string
}

const PostsRelated: React.FC<PostsRelatedProps> = ({ slug, image, title }) => {
    return (
        <article className="mod-article toi1">
            <div>
                <section role="button" className="mod-media">
                    <figure role="button" className="mod-figure --horizontal">
                        <Link to={'/' + slug}>
                            <picture className="mod-picture">
                                <img src={image.asset.url + '?auto=format&q=80'} alt={image.asset.alt} className="com-image " loading="lazy" />
                            </picture>
                        </Link>
                    </figure>
                </section>
            </div>
            <section className="mod-description">
                <h2 className="com-title --xs"><Link to={'/' + slug} className="com-link">{title}</Link></h2>
            </section>
        </article>
    )
}

export default PostsRelated
