import React from 'react'
import { imageUrlFor } from './image-url.js';
const BlockContent = require('@sanity/block-content-to-react');

const highlight = (props) => {
    return <mark className="hl_underline">{props.children}</mark>
}

const toggleZoom = () => {
    const activeClass = "--active"
    const el = document.getElementById("media-zoom");
    if (el.classList.contains(activeClass)) {
        el.classList.remove(activeClass);
        return;
    }
    el.classList.add(activeClass);
}

export const PostDetailSerializers = {
    types: {
        mainImage: (props) => {
            const { alt, caption, asset } = props.node;
            const imageUrl = imageUrlFor(asset._ref).url();
            return (
                <div>
                    <section role="button" className="mod-media --zoom" id="media-zoom" onClick={() => toggleZoom()}>
                        <figure role="button" className="mod-figure --horizontal">
                            <picture className="mod-picture">
                                <img src={imageUrl + '?auto=format&q=80'} alt={alt} width="1558" height="874" className="com-image" />
                            </picture>
                            <figcaption className="mod-figcaption">
                                <span className="com-text --caption --twoxs">{caption}</span>
                            </figcaption>
                        </figure>
                    </section>
                </div >
            )
        },

        block: (props) => {
            const { style = 'normal' } = props.node

            if (style === 'blockquote') {
                return (
                    <blockquote className="mod-paragraph ">
                        <p className="com-paragraph --sueca --font-bold  --m">{props.children}</p>
                    </blockquote>
                    // <blockquote>
                    //     <p>{props.children}</p>
                    // </blockquote>
                )
            }

            if (style === 'normal') {
                return <p className="com-paragraph --s">{props.children}</p>
            }

            return BlockContent.defaultSerializers.types.block(props)
        },
    },
    marks: { highlight }
}