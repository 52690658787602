import React from 'react'

const Disclaimer: React.FC = () => {
    return (
        <p className="disclaimer">
            Material de uso interno sin fines comerciales. Prohibida su distribución y/o viralización de ningún tipo. Propiedad de grupo BE.
        </p>
    )
}

export default Disclaimer
