export const getFragmentDate = (date) => {
    let objToday = new Date(date),
        weekday = new Array('Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'),
        dayOfWeek = weekday[objToday.getDay()],
        dayOfMonth = (objToday.getDate() < 10) ? '0' + objToday.getDate() : objToday.getDate(),
        months = new Array('Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'),
        curMonth = months[objToday.getMonth()],
        curYear = objToday.getFullYear(),
        curHour = objToday.getHours() < 10 ? "0" + objToday.getHours() : objToday.getHours(),
        curMinute = objToday.getMinutes() < 10 ? "0" + objToday.getMinutes() : objToday.getMinutes();

    return {
        curHour,
        curMinute,
        dayOfWeek,
        dayOfMonth,
        curMonth,
        curYear
    }
}

export const getDate = (date) => {
    const { dayOfWeek, dayOfMonth, curMonth, curYear } = getFragmentDate(date);
    return `${dayOfWeek} ${dayOfMonth} de ${curMonth} de ${curYear}`
}

export const getHour = (date) => {
    const { curHour, curMinute } = getFragmentDate(date);
    return `${curHour + ":" + curMinute} hs`
}