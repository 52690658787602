import React, { useEffect, useState } from "react"
import Layout from "../../components/Layout/Layout"
import { graphql } from "gatsby"
import {
  toPlainText,
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../services/helpers'
import Seo from '../../components/SEO/Seo'
import PostDetail from '../../components/Posts/PostDetail'
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import NavMobile from "../../components/NavMobile"
import SideMenu from "../../components/SideMenu"

const PostsTemplate = ({ data }) => {
  const [scrollClass, setScrollClass] = useState<string>('')

  const { sanityPost } = data
  const {
    slug,
    title,
    publishedAt,
    mainImage,
    _rawBody,
    _rawExcerpt,
    author,
    categories
  } = sanityPost

  const seoContent = {
    title: "Últimas noticias de Argentina y el mundo - LA NACION",
    description:
      "Todas las noticias de Argentina y el mundo: últimas noticias en actualidad, deportes, coronavirus, economía, política, y tecnología. Mantenete informado sobre las novedades de Argentina en LA NACION.",
    image:
      "https://res.cloudinary.com/anterux/image/upload/f_auto/v1626304075/diario-digital-clone/placeholderLN.jpg",
    url: ``,
    article: true,
  };

  const latestPostsNodes = (data || {}).latestPosts
    ? mapEdgesToNodes(data.latestPosts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  const relatedPostsNodes = (data || {}).latestPosts
    ? mapEdgesToNodes(data.relatedPosts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  useEffect(() => {
    var lastScroll = 0;
    if (typeof window !== `undefined`) {
      window.onscroll = function () {
        let currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value

        if (currentScroll > 0 && lastScroll <= currentScroll) {
          lastScroll = currentScroll;
          setScrollClass('--scrollDown');
        } else {
          lastScroll = currentScroll;
          setScrollClass('--scrollUp');
        }
      }
    }
  }, []);

  return (
    <Layout>
      <Seo
        content={seoContent}
      />
      <div id="fusion-app">
        <div id="wrapper" className={`nota noticia ${scrollClass}`}>
          <Header />

          <NavMobile />
          <SideMenu />
          <div>
            <PostDetail
              title={title}
              excerpt={_rawExcerpt}
              publishedAt={publishedAt}
              body={_rawBody}
              mainImage={mainImage}
              authorName={author.name}
              category={categories[0]}
              relatedPosts={relatedPostsNodes}
              latestPosts={latestPostsNodes}
            />

            <div className="lay-sidebar">
              <div className="sidebar__main">
                <div className="mod-banner --adhesion_dsk --fixed --close">
                  <button
                    type="button"
                    aria-label="Close"
                    className="icon-close"
                    title="Cerrar publicidad"
                  />
                  <div
                    id="adhesion_dsk"
                    className="com-banner --adhesion_dsk --fixed"
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PostsQuery($id: String!, $category: String!) {
    
    sanityPost(
      slug: { current: { eq: $id } }
      ) {
      slug {
        current
      }
      title
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      publishedAt
      _rawBody
      mainImage {
        asset {
          url
        }
        caption
        alt
      }

      author {
        name
      }

      categories {
        title
      }
    }

    latestPosts: allSanityPost(
      limit: 12
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: $id } }, publishedAt: { ne: null }, enabled: {eq: true} }
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          title

          mainImage {
            asset {
              url
            }
            caption
            alt
          }
        }
      }
    }

    relatedPosts: allSanityPost(
      limit: 3
      sort: {fields: [publishedAt], order: DESC}
      filter: {slug: {current: {ne: $id}}, publishedAt: {ne: null}, enabled: {eq: true}, categories: {elemMatch: {id: {eq: $category}}}}
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          mainImage {
            asset {
              url
            }
            caption
            alt
          }
        }
      }
    }
  }
`

export default PostsTemplate
